@font-face {
  font-family: 'Poppins';
  src: url('./resources/fonts/Poppins-Regular.ttf');
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.adminTitle {
  margin-top: 72px;
  padding-left: 8px;
  font-size: 32px;
  font-weight: 800;
  color: #272c45;
}

.home {
  background: url(https://zoonosis.malvinasargentinas.gob.ar/background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 3em;
  background: #ffffff;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.homeTitle {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: 800;
  color: #272c45;
}

.bookingTitle {
  justify-content: center;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: 800;
  color: #272c45;
  margin-top: 16px;
}

.bookingSubtitle {
  justify-content: center;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #9b9b9b;
}

.homeSubtitle {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #9b9b9b;
}

.homeButton {
  width: 200px;
  margin-right: 10px;
}

.homeButtons {
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
}

.leftButton {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-bottom: 16px;
}

.rightButton {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-bottom: 16px;
}

@media (min-width: 700px) {
  .card {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    width: 30%;
    padding: 3em;
    border-radius: 0.3em;
    background: #ffffff;
    font-family: "Poppins", sans-serif;
    text-align: center;
  }

  .leftButton {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-bottom: 16px;
  }

  .leftButton Button {
    margin-right: 8px;
  }

  .rightButton {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    margin-bottom: 16px;
  }

  .rightButton Button {
    margin-left: 8px;
  }

  .signupDeletePet Button {
    width: 90%;
    max-width: 300px;
  }

  .loginButton {
    display: flex;
    flex: 1;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
  }

}

.bookingStepperContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
}

.MuiStepper-root {
  max-width: 650px;
  min-width: 400px;
}

.bookingStepTitle {
  display: flex;
  flex:1;
  padding: 24px;
  padding-top:0;
  font-family: "Poppins", sans-serif;
  justify-content: center;
  font-size: 18px;
  font-weight: 400;
  color: #272c45;
}

.plansContainer {
  display: flex;
  flex: 1;
  justify-content: center;
}

.plans {
  width: 100%;
  max-width: 512px;
  height: auto;
}

.signupDeletePet {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 16px;
}

.signUpPetsPrice {
  font-family: 'Poppins';
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 16px;
  font-size: 20px;
  font-weight: bold;
}

.widthContainer {
  display: flex;
  flex: 1;
  justify-content: center;
}

.innerWidthContainer {
  display: flex;
  flex: 1;
  max-width: 512px;
}

.signupPaymentAlertTitle {
  font-size: 16px;
  color: #ef4565;
  font-weight: bold;
}

.signupPaymentAlertContent {
  font-size: 16px;
  color: #9b9b9b;
}

.signupPaymentPrice {
  display: flex;
  flex:1;
  padding-top: 8px;
  padding-bottom: 8px;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #272c45;
}

.signupPaymentDiscount {
  font-size: 16px;
  color: #3c763d;
  margin-bottom: 8px;
}

.signupPaymentButtonContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 16px;
  color: #000;
}

.centeredButtonContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 16px;
  color: #000;
}

.signupPaymentDisclosure {
  font-size: 16px;
  color: #9a6948;
  margin-bottom: 8px;
}

.signupPaymentNext {
  font-size: 16px;
  color: #9b9b9b;
  margin-bottom: 8px;
}

.bookingContainerButton {
  margin-top: 24px;
}

.dropzonePlaceholder {
  border-bottom-width: 1px;
  border-color: '#888';
  border-bottom-style: solid;
  margin-bottom: 0;
  margin-top: 18px;
  padding-Bottom: 8px;
  font-size: 18px;
}

.termsWhatsapp {
  text-decoration: none;
  color: forestgreen;
  cursor: pointer;
}

.termsEmail {
  text-decoration: none;
  color: #d7a27b;
  cursor: pointer;
}

.bookingList {
  min-width: 100px;
  max-width: 400px;
  max-height: 360px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.bookingListItem {
  min-width: 100px;
  max-width: 400px;
  background-color: #D0F271;
  margin-bottom: 2px;
  border-radius: 10px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dayBookingListItem {
  min-width: 100px;
  max-width: 400px;
  background-color: #D0F271;
  margin-bottom: 2px;
  border-radius: 10px;
  height: 46px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dayBookingTakenListItem {
  min-width: 100px;
  max-width: 400px;
  background-color: #D0F271;
  margin-bottom: 2px;
  border-radius: 10px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.bookingListItem:hover {
  background-color: #95c11e;
  color: white;
  cursor: pointer;
}

.dayBookingListItem:hover {
  background-color: #95c11e;
  color: white;
  cursor: pointer;
}

.dayBookingTakenListItem:hover {
  background-color: #95c11e;
  color: white;
  cursor: pointer;
}

.selectedBookingListItem {
  min-width: 100px;
  max-width: 400px;
  background-color: #122F55;
  color: white;
  margin-bottom: 2px;
  border-radius: 10px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.selectedDayDoneBookingListItem {
  min-width: 100px;
  max-width: 400px;
  background-color: forestgreen;
  color: white;
  margin-bottom: 2px;
  border-radius: 10px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.selectedDayNotDoneBookingListItem {
  min-width: 100px;
  max-width: 400px;
  background-color: firebrick;
  color: white;
  margin-bottom: 2px;
  border-radius: 10px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.selectedDayBookingListItem {
  min-width: 100px;
  max-width: 400px;
  background-color: #122F55;
  color: white;
  margin-bottom: 2px;
  border-radius: 10px;
  height: 46px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.selectedDayBookingTakenListItem {
  min-width: 100px;
  max-width: 400px;
  background-color: #122F55;
  color: white;
  margin-bottom: 2px;
  border-radius: 10px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.MuiOutlinedInput-input {
  padding: 14px 14px !important;
}

.MuiFormControlLabel-label {
  font-size: 24px !important;
}

.react-calendar__month-view__weekdays__weekday > abbr {
  font-size: 14px !important;
}
